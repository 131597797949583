import React, { useState, useRef, useContext, useEffect, useCallback } from "react";
import {
  TimelineViews,
  ScheduleComponent,
  ViewsDirective,
  ViewDirective,
  ResourcesDirective,
  ResourceDirective,
  Inject,
  Resize,
  DragAndDrop,
} from "@syncfusion/ej2-react-schedule";
// import dataSource from "../../datasource.json";
import HotelSearch from "../propertysearch/HotelSearch";
import axios from "axios";
import { BookingContent } from "../bookingContext/BookingContext";
import ToolTipComponent from "../tooltip/ToolTipComponent";
import SelectHotelGrid from "./SelectHotelGrid";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import TrRequest from "../ghrequestComponent/TrRequest";
import { isNullOrUndefined } from "@syncfusion/ej2-base";
import "../../assets/scss/timeline-resource-grouping.css";
import "../../assets/scss/custom.scss";
import EditorTemplate from "../editortemplatepopup/EditorTemplate1";
import AddAdditionalFields from "../editorTableComponent/AddAdditionalFields";
import ChangeEventTemplate from "../eventChange/ChangeEventTemplate";
import BookingCancelTemplate from "../editorTableComponent/BookingCancelTemplate";
// import LoadSpinner from "../spinner/Spinner";
import NewBookingPopupTemplate from "../editortemplatepopup/NewBookingPopupTemplate";
import BlockDeleteData from "../editorTableComponent/BlockDeleteData";
import Errow_page from "../error_component/Errow_page";
import SpinnerTemplate from "../error_component/Spinner";
import $ from "jquery";
import Intro from "./Intro";
import BookingModifyCancelOptionPopup from "../editorTableComponent/BookingModifyCancelOptionPopup";
import ModifyShfiting from "../eventChange/ModifyShfiting";
//import GetBookingCancellationDetails from "../editorTableComponent/GetBookingCancellationDetails(remove)";

/**
 * schedule timeline resource grouping sample
 */
function TimelineGrouping() {
  const {
    propertyID,
    clientDetails,
    token,
    showerror_template,
    is_loading,
    proxy_Url,
    setSchedulerData,
    scheduler_Data,
    isLoadingfunc,
    show_error_templatefunc,
    errorClassNamefunc,
    getErrorMessagefunc,
    set_err_msg,
    set_Error_Message_func,
    set_modify_err_msg,
    // getsetentityname,
    setStarttime,
    setEndtime,
    // cancel_event,
    formatDateddmmyy,
    set_occupency,
    occupency_Data,
    gridstartend_date,
    // gridstartenddate,
    // convertdatetommddyy,
    property_details,
    gridoccupencydatafunc,
    // propnameid,
    // ghName,
    newbookingpopupfunc,
    showGh,
    // setShowGhfunc,
    showNewBookingfunc,
    shownewbooking,
    // setShowTrfunc,
    showTr,
    changeeventdatafunc,
    change_event_data,
    stayChangedatefunc,
    setEditordatafunc,
    newbookingpopupdatafunc,
    getroomidfunc,
    getgroupidfunc,
    // room_id,
    ghaccessdata,
    bookingmodifycancelfunc,
    setSchedulerData_original_func,
    ghroom_id,
    error_msg_class,
    setErrorMsgClassfunc,
    GH_property_ClientName,
    convertdatetommddyy,
    seteventchangedatefunc,
    setstaychangedate,
    staydaystodisplayfunc,
    setOccupencyData,
    getGHClientName,
    getPropertyDetails,
    setshowGhname,
    getTrSelectDetails,
    blockdeletefunc,
    showblockdelete,
    showblockdeletefunc,
    dayscountfunc,
    dayscount,
    stayshiftfunc,
    showinto,
    roomshiftingdatafunc,
    changeroomidfunc,
    testdate,
    setinitialday,
    initial_day,
    User_Details,
    editmodificationfunc
  } = useContext(BookingContent);

  //const data = extend([], dataSource.resourceData, null, true);
  const workDays = [0, 1, 2, 3, 4, 5];
  // show tr list

  //grid start date
  const [editordata, setEditordata] = useState([]);
  const [properrormsg, setproperrormsg] = useState([]);
  // initial date count
  const [iniDate, setIniDate] = useState(31);
  //send data to trtable components
  const [trData, settrData] = useState([]);
  const [addifields, setAddifields] = useState(false);
  // editor change data
  const [showChangedata, showChangedatafunc] = useState(false);
  const [editpopup, showEditpopup] = useState(false);
  const [cancelpopup, showcancelpopup] = useState(false);
  const [roomshiftingmodify, showroomshiftingmodify] = useState(false);
  const [table_list_count, settable_list_count] = useState();
  const [getErrorMessage, setgetErrorMessagefunc] = useState([]);
  const [modificationerrormsg, setmodificationerrormsgfunc] = useState([]);
  const [backdatedbooking, setbackdatedbooking] = useState([]);
  const [resererrormsg, setresererrormsg] = useState([]);
  const [err_msg, seterr_msg] = useState([]);
  const [dragstartendidmatch, setdragstartendidmatch] = useState([]);
  const [modifyshiftdata, setmodifyshiftdata] = useState([]);
  //cancel template hide
  const [opencanceltem, setopencanceltem] = useState(false);
  const [dragchek, setdragchek] = useState(false);
  const User_Details_id = JSON.parse(User_Details);

  //before 7 days date booking
  var date_7 = new Date();
  date_7.setDate(date_7.getDate() - (User_Details_id?.Id == 1625 ? 368 : User_Details_id?.Id == 2522 ? 183 : 15));
 // date_7.setDate(date_7.getDate() - 30);
  //check in data allowed for modification
  let readonly = scheduler_Data[0]?.scheduleData?.map((item) =>
    new Date(item?.CheckInDt) < date_7
      ? item?.Type !== "CheckIn"
        ? { ...item, IsDone: true }
        : { ...item, IsDone: false }
      : { ...item, IsDone: false }
  );

  //before 7 days date booking
  //current month days
  //var daysInCurrentMonth=new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate()

  //current month days

  let bookdata = [];
  // let pop = useRef();
  // let ref = useRef({ pop });
  // let startObj = useRef();
  // let endObj = useRef();

  let scheduleObj = useRef();

  //set session id
  sessionStorage.setItem("credentials", localStorage.getItem("accessToken"));
  sessionStorage.setItem("access_Token1", localStorage.getItem("accessToken1"));
  let ghuserId = JSON.parse(localStorage.getItem("accessToken"))?.Id;

  let adaptiveUI = false;
  let catTitleName;
  let roomTitleName;
  let trlistCount;
  let checkSlotAvailable;
  let trclickcount = 0;
  let ghpropname = document.querySelector("#ghproperty")?.value;

  const headers = {
    "Content-Type": "application/json",
    "x-access-token": `${token}`,
  };

  useEffect(() => {
    if (GH_property_ClientName[0]?.length === 0) {
      showNewBookingfunc(false);
    }
    // else {
    //showNewBookingfunc(true);
    // }

    window.addEventListener("resize", resDate);
    resDate();
    //  scheduleObj?.current?.refresh();

    setSchedulerData({});
    setSchedulerData_original_func({});
    setOccupencyData({});
    getGHClientName([]);
    getPropertyDetails([]);
    setshowGhname("");

    const fromDate = new Date("2023-09-10");
    const toDate = new Date("2023-09-15");

    // Calculate the time difference in milliseconds
    const timeDifference = toDate - fromDate;

    // Convert milliseconds to days (assuming 1 day = 24 hours = 60 minutes = 60 seconds = 1000 milliseconds)
    //setinitialday(Math.floor(timeDifference / (1000 * 60 * 60 * 24)));
    //setinitialday(30);
    resDate();
  }, []);

  const loadingIndicator = { indicatorType: "Shimmer" };
  // prevent drag and resize on item
  const onItemDrag = (args) => {
    showEditpopup(false);
    setdragchek(true);
    args?.element?.classList?.remove("max-h-50");
    args?.element?.classList?.remove("h-33");
    //args?.element?.classList?.remove("room-blue");

    //restrict bed level shifting start
    if (args.data?.BookingLevel === "Bed" && args?.name === "dragStart") {
      //args.cancel = true;
      //restrict bed level shifting start
      stayshiftfunc("dragevent");
      showEditpopup(false);
    } else {
      showEditpopup(false);
      setdragstartendidmatch([args.data]);
      // if (new Date(args?.data?.StartTime) < date_7) {

      //   setgetErrorMessagefunc(["Back date modification not allowed"]);
      //   setTimeout(() => {
      //     setgetErrorMessagefunc([]);
      //   }, 2000);
      //   args.cancel = true;
      // }
      if (ghaccessdata[0]?.ModifyResFlg === false) {
        setgetErrorMessagefunc(["GH Modify access not available, Please contact your Admin"]);
        setTimeout(() => {
          setgetErrorMessagefunc([]);
        }, 2000);
        args.cancel = true;
      } else if (args.data?.BookingLevel === "Blocked") {
        args.cancel = true;
      } else {
        args.cancel = false;
      }
  
    }
    // setTimeout(() => {
    //   showEditpopup(false);
  
    // }, 1000);
       
       

      

     
   
    
  };
  const error_close = () => {
    setgetErrorMessagefunc([]);
    setresererrormsg([]);
    seterr_msg("");
  };
  const cls_err_msg = () => {
    set_Error_Message_func([]);
  };

  //set room layout

  const resDate = () => {
    let w = window.innerWidth;
    if (w > 700 && w < 800) {
      setSchedulerData([]);
      setSchedulerData_original_func([]);
    }
    if (w > 760) {
      if (initial_day !== 0) {
        setIniDate(parseInt(initial_day));
        dayscountfunc(parseInt(initial_day));
      } else {
        setIniDate(31);
        dayscountfunc(31);
      }

      adaptiveUI = false;
    } else {
      dayscountfunc(7);
      adaptiveUI = true;
    }
  };

  // tooltip template
  const tooltipTemplate = useCallback((props) => {
    return <ToolTipComponent {...props} />;
  }, []);

  //editor window on single click
  const onCellClick = (args) => {
    scheduleObj?.openEditor(args, "Add");
    let entityName = document.querySelector("#Entity_select").value;
    let g_index = args.groupIndex;
    let room_name = document.querySelectorAll(".e-resource-cells > div.e-resource-text");
    let cat_name = document.querySelectorAll(".e-resource-cells");
    roomTitleName = room_name[args.groupIndex] !== ("" || undefined) ? room_name[args.groupIndex].innerHTML : "";
    for (let i = g_index; i >= 0; i--) {
      if (cat_name[i]?.parentElement?.previousElementSibling?.children[0].classList.contains("e-parent-node")) {
        catTitleName = cat_name[i - 1].children[1].innerHTML;
        break;
      }
    }

    //to set booking title
    document.querySelector(
      ".e-title-text"
    ).innerHTML = `<b style="font-size: 14px;">Booking for</b> <span style="font-weight:bold;color: #005ac3;">${entityName}</span> / <span style="font-weight:bold; font-size: 14px;">${catTitleName} / <span class='room_title'>${roomTitleName}</span>`;

    if (GH_property_ClientName[0]?.length === 0) {
      setproperrormsg(["Property has no contracts, please contact hummingbird"]);
      setTimeout(() => {
        setproperrormsg([]);
      }, 2000);
    } else {
      setproperrormsg([]);
    }
   
  };

  //Event Rendered
  const onEventRendered = (args) => {
    //pointer events none

    document.querySelector(".control-wrapper").classList.remove("cursorevents");

   //add bed type 1/2
   let trimspacebedtype = args?.data?.Bedtype || "";
    let trimspace = trimspacebedtype.trim().replace(/\s+/g, '').replace(/[^a-z0-9]/gi, '').toLowerCase();
 
   let combinerowcol = ((args?.data?.GHRoomid + args?.data?.GHgroupid - 1) * 42) + 2;
   let bed2size = 43 / 2;
   let computedStyle=0;




    
  
   switch (trimspace) {
     case "bed1":
       args.element.style.top = combinerowcol + "px";
       break;
     case "bed2":
     args.element.style.top = combinerowcol + bed2size + "px";
     break;
     default:
   args.element?.classList?.add("room-bottom1");
   }


    switch (args?.data?.Type) {
      case "CheckIn":
        args.element.classList.add("checkedin_booking");
        break; // break added
      default:
    }

    switch (parseInt(args?.data?.bedcount)) {
      case 1:
        args?.element?.classList?.add("max-h-100");
        break; // break added
      case 2:
        args?.element?.classList?.add("max-h-50");
      // case 3:
      //   args.element.classList.add("h-33");
      break; // break added
      default:
        args.element.classList.add("h-33");
    }

    switch (args?.data?.BookingLevel) {
      case "Room":
        args.element.classList.add("room-green");
        args.element.classList.add("room-green_border1");
        args.element.classList.remove("room-blue");
        args.element.classList.remove("room-occupency");

        break;
      case "Bed":
        args.element.classList.add("room-blue");
        args.element.classList.remove("room-green");
        args.element.classList.remove("room-occupency");
        break;
      case "":
        args.element.classList.add("room-black");
        args.element.classList.remove("room-green");
        args.element.classList.remove("room-blue");
        args.element.classList.remove("room-occupency");
        break;
      case "Blocked":
        args.element.classList.add("room-black");
        args.element.classList.remove("room-green");
        args.element.classList.remove("room-blue");
        args.element.classList.remove("room-occupency");
        break;
      default:
        args.element?.classList?.add("room-occupency");

        
        //pointer events none
        //document.querySelector(".control-wrapper").classList.add("cursorevents");
        // GH_property_ClientName[0]?.length === 0 &&
        if (document.querySelector("#com-email-1").checked === true) {
          document.querySelector(".control-wrapper").classList.add("cursorevents");
        }

      // document.querySelector(".e-current-time").style.display="none"
    }
    let cellHeight = scheduleObj.element.querySelectorAll(".e-content-wrap tr td:first-child");
    let cellLength = cellHeight.length;
    let setCellheight = scheduleObj.element.querySelectorAll(".e-event-table .e-appointment-container");
    for (let i = 0; i < cellLength; i++) {
      if (setCellheight[i].firstChild !== null) {
        setCellheight[i].firstChild.setAttribute("style", `height:${cellHeight[i].clientHeight}px !important`);
      }
    }
  };

  const onMoreEventsClick = (args) => {
    args.cancel = true;
  };

  //check events for past events

  const onDragStop = (args) => {
    args.cancel = onEventCheck(args);
    //roomshiftingdatafunc([]);

    //args?.event?.target?.classList?.map(item => item === "e-resource-group-cells" && (args.cancel = true))
    // if(args?.event?.target?.classList.includes("e-resource-group-cells")) {
    //   //args.cancel=true;
    // }
    if (args?.event?.target?.classList.contains("e-resource-group-cells")) {
      args.cancel = true;
    }

    if (args.data.Type === "CheckIn") {
      showChangedatafunc(false);
      args.cancel = true;
    }
    // target data cells
    if (args.data && args.target) {
      var cellData = scheduleObj.getCellDetails(args.target);

      var occupiedEvents = scheduleObj.getEvents(cellData.startTime, cellData.endTime, cellData.RoomName);

      // var occupiedEvent = occupiedEvents.filter(
      //   (app) => parseInt(app.RoomId) === parseInt(args.data.RoomId) && app);
      var occupiedEvent1 = occupiedEvents.filter((app) =>
        parseInt(app.GHRoomid) === parseInt(cellData?.groupIndex) - parseInt(app?.GHgroupid - 1) ? app : null
      );
      //get drag events

      // var getdragEvents = scheduleObj.getEvents(args.data.startTime, args.data.endTime, cellData.RoomName);

      //drag event bed or room control if room no drop

      // if (!scheduleObj.isSlotAvailable(args.data)) {
      //    if (occupiedEvent?.BookingLevel === "Bed") {
      //     if(args.data?.BookingLevel === "Bed"){
      //       args.cancel = false;
      //     }
      //     //args.cancel= true;

      //    } else {
      //      args.cancel = true;
      //    }
      // }
      // drag check

      if (!scheduleObj.isSlotAvailable(args.data)) {
        if (occupiedEvent1[0]?.BookingLevel === "Bed" && args.data?.BookingLevel === "Bed") {
          args.cancel = false;
          setdragchek(false);
        } else {
          args.cancel = true;
          setdragchek(false);
          
        }

        //   //ok
        // } else if (args.data?.BookingLevel === "Bed" && occupiedEvent?.BookingLevel === "Room") {
        //   args.cancel = true;

        // } else if (args.data?.BookingLevel === "Room") {
        //   args.cancel = true;
        // } else {
        //   args.cancel = false;

        // }

        //ok
      }
      roomshiftingdatafunc([]);
      //block room shifting worked
      if (dragstartendidmatch[0]?.GHRoomid !== args.data.GHRoomid) {
        //roomshifting
        // args.cancel=true;
        let room_shift_filter_data = scheduler_Data[0]?.roomData.filter(
          (item) => parseInt(item?.id) === parseInt(args?.data?.GHRoomid)
        );
        roomshiftingdatafunc(room_shift_filter_data);

        // args.cancel = true;
      }
    }
    stayshiftfunc("dragevent");
    setTimeout(() => {
     setdragchek(false); 
    }, 2000);
    showEditpopup(false);
  };

  const onResizeStop = (args) => {
    //check in data allowed for modification

    if (args?.data?.Type !== "CheckIn") {
      args.cancel = onEventCheck(args);
    }
    //check in data allowed for modification
    if (args.data?.BookingLevel === "Blocked") {
      args.cancel = true;
    }

    // else {
    //   if (!scheduleObj.isSlotAvailable(args.data)) {
    //     args.cancel = true;
    //   }
    // }

    var eventsInRange = scheduleObj.getEvents(args.data.StartTime, args.data.EndTime, args.data.RoomName);

    var listOfEventsInRange = eventsInRange.filter((app) => {
      return app.Id !== args.data.Id && app.TaskId === args.data.TaskId && app.RoomId === args.data.RoomId;
    });

    if (args.data.BookingLevel === "Bed") {
    }
    let notbed = listOfEventsInRange.map((item) => item.BookingLevel !== "Bed");
    listOfEventsInRange.map((item) =>
      item.BookingLevel === "Bed" && args.data.BookingLevel === "Bed" ? (args.cancel = false) : (args.cancel = true)
    );

    // if (notbed?.length > 0) {
    //   args.cancel = true;
    // }
    stayshiftfunc("resizeevent");
  };
  const onEventCheck = (args) => {
    let eventObj = args.data instanceof Array ? args.data[0] : args.data;
    //return eventObj.StartTime < new Date();
    return new Date(eventObj.StartTime) < date_7;
  };

  // single click on booking edit start

  const onEventClick = (args) => {
   
 
  
    if (!args.event.RecurrenceRule) {
      scheduleObj?.openEditor(args.event, "Save");
    } else {
      scheduleObj.quickPopup.openRecurrenceAlert();
    }
    // get date for drag and resize
    let sdate = new Date(args?.event.StartTime);
    setStarttime(sdate);
    let edate = new Date(args?.event.EndTime);
    setEndtime(edate);
    
  
    // if(setopencanceltem === true){
    //   showEditpopup(true);
    // }
    
  };
  // single click on booking edit end
  //editor template
  const editorTemplate = useCallback((props) => {
    //   let result;

    return props !== undefined ? (
      <EditorTemplate
        getsavetrdata={getsavetrdata}
        table_list_count={table_list_count}
        addifields={addifields}
        setAddifields={setAddifields}
        editordata={editordata.data || editordata}
        setEditordata={setEditordata}
        props={props}
        trData={trData}
      />
    ) : (
      <div></div>
    );
  }, []);


  //popup open
  const onPopupOpen = (args) => {
    const user_response = JSON.parse(User_Details); // Parse the JSON string

    if(ghaccessdata[0]?.BookingResFlg == false) {
      args.cancel = true;
      setmodificationerrormsgfunc(["GH Booking access not available, Please contact your Admin"]);

            setTimeout(() => {
              setmodificationerrormsgfunc([]);
              show_error_templatefunc(false);
            }, 2000);
      return;
    }
    
    setmodifyshiftdata(args.data);
    args.cancel = true;
    //set stay days to default
    staydaystodisplayfunc(1);
    getTrSelectDetails([]);
    roomshiftingdatafunc([]);
    let SelectEntityName = document.querySelector("#Entity_select").value;
    // popup cancel for select entity name validation

    if (SelectEntityName === "Select Entity Name" && !args.data.BookingLevel) {
      args.cancel = true;
    } else {
      args.cancel = true;
      if (args?.data?.Type !== "Blocked") {
        if (args?.data?.Type === "Booked" || args?.data?.Type === "CheckIn") {
          if (new Date(args?.data?.StartTime) >= date_7) {
            args.cancel = true;
            if (args?.data?.Type === "Booked") {
              showEditpopup(false);
       
              showChangedatafunc(false);
              isLoadingfunc(true);
            }
            let s_date = document.querySelector("#STime")?.value.split("/");
            let e_date = document.querySelector("#ETime")?.value.split("/");

            let bodyData = {
              modifydata: {
                RoomId: args?.data?.RoomId,
                BookingCode: args?.data?.BookingCode,
                UserId: ghuserId,
              },
              gridData: {
                PropertyId: property_details[0]?.PropertyId || property_details[0]?.Id || propertyID[0],
                FromDte: s_date[1] + "/" + s_date[0] + "/" + s_date[2],
                ToDte: e_date[1] + "/" + e_date[0] + "/" + e_date[2],
                PropertyName:
                  property_details[0]?.PropertyName ||
                  property_details[0]?.Property ||
                  document.querySelector("#ghproperty")?.value,
              },
            };

            args.cancel = true;
            bookingmodifycancelfunc(bodyData);

            //  setTimeout(() => {

            //    //showEditpopup(!editpopup);

            //    isLoadingfunc(false);
            //  }, 1000);
          }

          if (args?.data?.Type === "CheckIn") {
            showChangedatafunc(false);
            args.cancel = true;
            setmodificationerrormsgfunc(["Guest checked-in, cancellation not allowed"]);

            setTimeout(() => {
              setmodificationerrormsgfunc([]);
              show_error_templatefunc(false);
            }, 2000);
          }
        }
        args.cancel = true;
        if (args?.data?.Type === "CheckIn") {
          args.cancel = true;
          showEditpopup(false);
         

          isLoadingfunc(false);
        } else {
          args.cancel = true;
          if (!args.data.BookingLevel) {
            showEditpopup(false);
           
          } else {
         
            

    //remover for reason of some times cancel popup not opening      if(args.target?.attributes.class?.nodeValue.includes("max-h-50 h-33") === true )
     
    //if((args.data?.BookingLevel ==="Room" || args.data?.BookingLevel ==="Bed" ) && (args.data?.IsAllDay   )
    if (args.data && args.data.hasOwnProperty('IsAllDay') && (args.data.IsAllDay === true || args.data.IsAllDay === false)) { 
              setTimeout(() => {
               showEditpopup(false);  
             }, 100);
           }
           else {
             showEditpopup(true);

             /* modify data for cancen popup */
             let s_date = document.querySelector("#STime")?.value.split("/");
            let e_date = document.querySelector("#ETime")?.value.split("/");

            let bodyData = {
              modifydata: {
                RoomId: args?.data?.RoomId,
                BookingCode: args?.data?.BookingCode,
                UserId: ghuserId,
              },
              gridData: {
                PropertyId: property_details[0]?.PropertyId || property_details[0]?.Id || propertyID[0],
                FromDte: s_date[1] + "/" + s_date[0] + "/" + s_date[2],
                ToDte: e_date[1] + "/" + e_date[0] + "/" + e_date[2],
                PropertyName:
                  property_details[0]?.PropertyName ||
                  property_details[0]?.Property ||
                  document.querySelector("#ghproperty")?.value,
              },
            };
             bookingmodifycancelfunc(bodyData);
           }
          }
          //  showEditpopup(true);
        }
      } else {
        args.cancel = true;
        showblockdeletefunc(true);
        blockdeletefunc(args.data);
      }
    }

    if (new Date(args?.data?.StartTime) < date_7) {
      // getErrorMessagefunc([]);
      // //cursor events block
      // if (GH_property_ClientName[0]?.length !== 0) {
      //   getErrorMessagefunc(["Back dated booking not allowed"]);
      //   setTimeout(() => {
      //     getErrorMessagefunc([]);
      //     show_error_templatefunc(false);
      //   }, 2000);
      // }
      args.cancel = true;
      if (!args.data.BookingLevel) {
        args.cancel = true;
        setbackdatedbooking(["Back dated booking not allowed"]);
        // getErrorMessagefunc();
        //show_error_templatefunc(true);
        setTimeout(() => {
          setbackdatedbooking([]);
          // show_error_templatefunc(false);
        }, 2000);
      } else {
        if (args.data.BookingLevel !== "Blocked") {
          if (new Date(args?.data?.StartTime) < date_7) {
            showEditpopup(false);
         
            args.cancel = true;
            getErrorMessagefunc([]);
            setmodificationerrormsgfunc(["Guest checked-in, cancellation not allowed"]);

            setTimeout(() => {
              setmodificationerrormsgfunc([]);
              show_error_templatefunc(false);
            }, 2000);
            args.cancel = true;
          } else {
            args.cancel = true;
          }
        } else {
          //room unblock for all past dates
          args.cancel = true;
          showblockdeletefunc(true);
          blockdeletefunc(args.data);
        }
      }
    } else {
      settable_list_count(0);
      localStorage.removeItem("editorbktype");
      trData.length = 0;
      settrData([]);
      // remove localStorage
      localStorage.removeItem("trdetails");

      if (SelectEntityName !== "Select Entity Name") {
        args.cancel = true;
        if (args.type === "Editor") {
          localStorage.removeItem("trInputstates");
          getroomidfunc(args.data.GHRoomid);
          getgroupidfunc(args.data.GHgroupid);

          let subjectElement = args.element.querySelector("#TRrequestid");
          if (subjectElement) {
            subjectElement.value = args.data.Subject || "";
          }
          let statusElement = args.element.querySelector("#EventType");
          statusElement?.setAttribute("name", "EventType");

          let occupencyElement = args.element.querySelector("#occupancy");
          occupencyElement?.setAttribute("name", "occupancy");

          let ClientRequestElement = args.element.querySelector("#ExtraccMail");
          if (ClientRequestElement) {
            ClientRequestElement.value = args.data.ClientRequest || "";
          }

          let ExtraccMailElement = args.element.querySelector("#ExtraccMail");
          if (ExtraccMailElement) {
            ExtraccMailElement.value = args.data.ExtraccMail || "";
          }

          let startTime = args.element.querySelector("#StartTime");
          if (startTime) {
            let ss = new Date(args.data.StartTime);
            let time = "12:00 PM";
            let date = formatDateddmmyy(ss.toLocaleDateString("en-US"));
            startTime.value = date + " " + time;
          }

          let endTime = args.element.querySelector("#EndTime");
          if (endTime) {
            //  let ss = new Date(args.data.EndTime);
            let ss = new Date(args.data.StartTime);
            let time = "11:59 AM";
            var Select_start_date = new Date(ss);
            Select_start_date.setDate(Select_start_date.getDate() + 1);
            let date = formatDateddmmyy(Select_start_date.toLocaleDateString("en-US"));
            endTime.value = date;
            // endTime.value = date + " " + time;
          }
          let sdate = document.querySelector("#StartTime")?.value;
          let edate = document.querySelector("#EndTime")?.value;
          let editorbktype = document.querySelector("#EventType")?.value;
          let editormaxguest = document.querySelector("#guestAllowed")?.value;
          let editoroccupency = document.querySelector("#occupancy")?.value;
          let ClientRequest = document.querySelector("#ClientRequest")?.value;
          let ExtraccMail = document.querySelector("#ExtraccMail")?.value;
          setEditordatafunc({
            sdate: sdate,
            edate: edate,
            editorbktype: editorbktype,
            editormaxguest: editormaxguest,
            editoroccupency: editoroccupency,
            ClientRequest: ClientRequest,
            ExtraccMail: ExtraccMail,
          });

          setEditordata({
            ...editordata,
            StartTime: sdate,
            EndTime: edate,
            BkType: editorbktype,
            maxallowed: editormaxguest,
            occupency: editoroccupency,
            ClientRequest: ClientRequest,
            ExtraccMail: ExtraccMail,
          });

          //}
        } // past date check

        // show new and edit popup window

        // if (args?.data?.Type === "Booked" || args?.data?.Type === "CheckIn") {
        // if (args?.data?.Type === "Booked" || args?.data?.Type === "CheckIn") {
        //   //  if (new Date() > args?.data?.StartTime) {
        //   if (new Date(args?.data?.StartTime) < date_7) {
        //     setgetErrorMessagefunc([]);
        //     setgetErrorMessagefunc(["Back date modification not allowed"]);
        //     args.cancel = true;
        //   }
        // }

        if (args?.data?.Type === "CheckIn") {
          showChangedatafunc(false);
          args.cancel = true;

          showEditpopup(false);
         
          isLoadingfunc(false);
          setresererrormsg(["Guest checked-in, cancellation not allowed"]);
          seterr_msg("Error");
          setTimeout(() => {
            error_close();
          }, 2000);
        } else {
        }
        if (!args.data.BookingCode) {
          args.cancel = true;
          showEditpopup(false);
       
          setTimeout(() => {
            localStorage.removeItem("trselect_data");
            localStorage.removeItem("tr_Input_states");
            newbookingpopupdatafunc(args.data);

            var Select_start_date = new Date(args.data?.StartTime);
            // Add a day
            Select_start_date.setDate(Select_start_date.getDate() + 1);

            newbookingpopupfunc({ ...args.data, EndTime: Select_start_date });
            showNewBookingfunc(true);
            setTimeout(() => {
              setopencanceltem(false);
            }, 100);
        
          });
        }
      } else {
        args.cancel = true;
        if (GH_property_ClientName[0]?.length === 0) {
          // setproperrormsg(["Property has no contracts, please contact hummingbird"]);
        } else {
          if (!args.data.BookingCode) {
            errorClassNamefunc("error_warning2");
            setresererrormsg(["Entity name is required"]);
            seterr_msg("Error");
            setTimeout(() => {
              error_close();
            }, 2000);
          }
        }
      }

      // delete event popup window content changed
      if (args.data?.Type === "Blocked") {
        let deletetitle = document.querySelector(".e-title-text");
        let deletetitle1 = document.querySelector("#QuickDialog_title");
        let deletetitlecontent = document.querySelector("#QuickDialog_dialog-content");
        let deletebutton = document.querySelector(".e-event-delete");
        let deletebutton_quickalert = document.querySelector(".e-quick-dialog-delete");
        args.cancel = true;
        blockdeletefunc(args.data);
        if (SelectEntityName !== "Select Entity Name") {
          args.cancel = true;
          showblockdeletefunc(true);
        }
        args.cancel = true;

        if (deletebutton_quickalert) {
          // setTimeout(() => {
          if (deletetitle) {
            deletetitle.innerHTML = "<p>Unblock Booking</p>";
            deletebutton.innerHTML = "<p>Unblock Room</p>";

            deletebutton_quickalert.innerHTML = "<p>Confirm</p>";
          }
          if (deletetitle1) {
            deletetitle1.innerHTML = "<p>Unblock Booking</p>";
            deletebutton.innerHTML = "<p>Unblock Room</p>";
          }
          if (deletetitlecontent) {
            deletetitlecontent.innerHTML = "<p>Are you sure you want to unblock this booking?</p>";
            deletebutton_quickalert.innerHTML = "<p>Confirm</p>";
          }
          // },500)
        }

        changeeventdatafunc([]);
      }
    }
  };

  //booking data api

  const getsavetrdata = async (data) => {
    bookdata = data;
  };

  const onActionComplete = (args) => {
    // isLoadingfunc(true);

    //get change room id
    if (args.requestType === "eventChanged") {
      let changedeventid = args?.changedRecords && args?.changedRecords[0]?.GHRoomid;
      let roomchangeid = scheduler_Data[0]?.roomData?.filter((item) => parseInt(item?.id) === parseInt(changedeventid));
      changeroomidfunc(roomchangeid);
    }
    // eventremoved function start
    
   
    if (args.requestType === "eventRemoved" && args.data[0]?.BookingLevel !== "Blocked") {
      args.cancel = true;
      if (args.data[0].BookingCode !== "0") {
        isLoadingfunc(true);
        let s_date = document.querySelector("#STime")?.value.split("/");
        let e_date = document.querySelector("#ETime")?.value.split("/");
        let bodyData = {
          modifydata: {
            RoomId: args.data[0]?.RoomId,
            BookingCode: args.data[0]?.BookingCode,
            UserId: `${ghuserId}`,
          },
          gridData: {
            PropertyId: property_details[0]?.PropertyId || property_details[0]?.Id || propertyID[0],
            FromDte: s_date[1] + "/" + s_date[0] + "/" + s_date[2],
            ToDte: e_date[1] + "/" + e_date[0] + "/" + e_date[2],
            PropertyName:
              property_details[0]?.PropertyName ||
              property_details[0]?.Property ||
              document.querySelector("#ghproperty")?.value,
          },
        };
        args.cancel = true;
        bookingmodifycancelfunc(bodyData);
      }
    }
    // covert date function
    function covertdate(data) {
      let fdate = data.split("-");
      return fdate[1] + "/" + fdate[2] + "/" + fdate[0];
    }
    // eventChange function start

    if (args.requestType === "eventChanged") {
      // get event change data

      //find and set date diff for event change start

      changeeventdatafunc({
        ...args.data[0],
        CheckInDt: covertdate(args.data[0]?.CheckInDt),
        CheckOutDt: covertdate(args.data[0]?.CheckOutDt),
      });
      showChangedatafunc(true);
 
      showEditpopup(false);
      isLoadingfunc(true);

      let get_diff_s_date = new Date(args.changedRecords[0].StartTime).getDate();
      let get_diff_e_date = new Date(args.changedRecords[0].EndTime).getDate();
      let diff_date = get_diff_e_date - get_diff_s_date < 1 ? 1 : get_diff_e_date - get_diff_s_date;

      var date_diff = new Date(args.changedRecords[0].StartTime);
      date_diff.setDate(date_diff.getDate() + diff_date);
      let change_end_date = args.changedRecords[0].EndTime;
      let change_start_date = args.changedRecords[0].StartTime;
      let c_data = {
        start_date: change_start_date,
        end_date: change_end_date,
        RoomName: args.changedRecords[0].RoomName,
        diff_date: diff_date,
      };

      stayChangedatefunc(c_data);
    }
    //find and set date diff for event change start
  };

  //popup close
  const onPopupClose = useCallback((args) => {
    trData.length = 0;
    settrData([]);

    trlistCount = document.querySelector(".trcount");

    if (args.type === "Editor" && !isNullOrUndefined(args.data)) {
      //  hidepopbtn(args);
      if (trlistCount || trclickcount > 0) {
        args.data.GHRoomid = ghroom_id;
        //room_id;
        // localStorage.getItem("roomid");
        //RoomTaskId;
        args.data.GHgroupid =
          //group_id;
          parseInt(localStorage.getItem("groupid"));
        //RoomProjectid;

        let subjectElement = args.element.querySelector("#TRrequestid");
        if (subjectElement) {
          args.data.Subject = subjectElement.value;
        }

        let statusElement = args.element.querySelector("#EventType");
        if (statusElement) {
          args.data.EventType = statusElement.value;
        }

        let occupencyElement = args.element.querySelector("#occupancy");
        if (occupencyElement) {
          args.data.OccupencyType = occupencyElement.value;
        }

        let ClientRequestElement = args.element.querySelector("#ExtraccMail");
        if (ClientRequestElement) {
          args.data.ClientRequest = ClientRequestElement.value;
        }

        let ExtraccMailElement = args.element.querySelector("#ExtraccMail");
        if (ExtraccMailElement) {
          args.data.ExtraccMail = ExtraccMailElement.value;
        }

        let startTime = args.element.querySelector("#StartTime");
        if (startTime) {
          args.data.StartTime = startTime.value;
        }

        let endTime = args.element.querySelector("#EndTime");
        if (endTime) {
          args.data.EndTime = endTime.value;
        }
      } else {
        args.cancel = true;
      }
    }

    setTimeout(() => {
      //refreshEventTemplate();
      localStorage.removeItem("editorstartdate");
      localStorage.removeItem("editorenddate");
    }, 1000);
  }, []);

  const onExportClick = () => {};

  // onActionBegin
  const onActionBegin = useCallback(
    (args) => {
      
      setTimeout(() => {
        setopencanceltem(false);
      }, 100);
      // {
      // }
    
      
      // setTimeout(() => {
      //    if(args?.requestType ==='eventChange'){
      //         setopencanceltem(true)
      //       }
      //       else {
      //         setopencanceltem(false)
      //       }

      // }, 1000);

      if (args?.data?.BookingLevel === "Blocked") {
        args.cancel = true;
      }
      // if (GH_property_ClientName[0]?.length === 0) {
      //   args.cancel = true;
      //   getErrorMessagefunc(["Property has no contracts, please contact hummingbird"]);
      //    } else {
      //      getErrorMessagefunc(["Back date booking not allowed"]);
      //     }

      if (args.requestType === "toolbarItemRendering") {
        let exportItem = {
          align: "Right",
          showTextOn: "Both",
          prefixIcon: "e-icons e-export-excel",
          text: "Excel Export",
          cssClass: "e-excel-export",
          click: onExportClick,
        };
        args.items.push(exportItem);
      }

      // testing
      if (args.requestType == "eventRemove" || args.requestType == "eventChange") {
        //        if (new Date() > args?.data?.StartTime) {
        //   setgetErrorMessagefunc([]);
        //   setgetErrorMessagefunc(["Back date modification not allowed"]);
        //     args.cancel = true;
        // }
        let Id = isNullOrUndefined(args.data[0]) ? args.data?.Id : args.data[0]?.Id;
        if (args.requestType == "eventRemove") {
          let data = args.data;
        }
      }

      // //get taskid
      let Task_id = args?.data?.GHRoomid;

      // checking past days
     

      if (args?.requestType === "eventChange") {
        // update data
        setSchedulerData({
          ...scheduler_Data[0],
          scheduleData: scheduler_Data[0]?.scheduleData.map((item) =>
            item?.BookingId === args.data?.BookingId || item?.BookingId === args.data.TRrequestid
              ? { ...item, StartTime: args.data.StartTime, EndTime: args.data.EndTime }
              : item
          ),
        });
        //send event change data
        seteventchangedatefunc(args.data);
        setTimeout(() => {
          showEditpopup(false);
          
          // setopencanceltem(true)
        }, 0);
        setTimeout(() => {
          showChangedatafunc(true);
          showEditpopup(false);
        }, 100);
      }

      if (args.requestType === "eventCreate") {
        //remove old datas on event creation
        settrData([]);

        if (checkSlotAvailable) {
          return false;
        } else {
          if (args.data[0].BookingType === "Room") {
            args.cancel = true;
            scheduleObj.rowAutoHeight = true;
            //return false;
          }
        }

        if (args?.data.StartTime < new Date()) {
          args.cancel = true;
        }

        args.cancel = onEventCheck(args);
      }
    },
    [scheduler_Data]
  );

  const getaddcount = useCallback((value) => {
    settable_list_count(value);
  }, []);
  const properror_close = () => {
    setproperrormsg([]);
  };

  const Errortemplate = () => {
    return (
      <>
        {properrormsg?.length > 0 && (
          <div className="error_message">
            <div className="error_Message_content">
              <div className="error_MSg_Con">
                <span>Error Message</span>{" "}
                {/* <div className="close_but bg-light" onClick={properror_close}>
                  X
                </div> */}
              </div>
              <div className="error_content t-center">
                {properrormsg?.map((item, i) => (
                  <p className="t-center" key={i}>
                    {item && ` ${item}`}
                  </p>
                ))}
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  // remove name of date removed
  const onRenderCell = useCallback(
    (args) => {
      // if (args.element.classList.contains('e-resource-text')){

      // }

      let dateOnly = "";
      if (args?.element?.classList?.contains("e-header-cells")) {
        dateOnly = args?.element?.children[0].innerHTML;
        let slicedate = dateOnly?.split(",").slice(0, 1);
        let day = dateOnly?.split(",")[1].slice(0, 4);
        let clide_date_only = slicedate[0]?.split(" ");
        args.element.children[0].innerHTML =
          clide_date_only[1]?.length > 1
            ? `<span>${clide_date_only[0]}</span>` + clide_date_only[1] + `<span>${day}</span>`
            : `<span>${clide_date_only[0]}</span>` + 0 + clide_date_only[1] + `<span>${day}</span>`;
      }
    },
    [scheduler_Data[0] && scheduler_Data[0].scheduleData]
  );

  //format date
  //   const formatDateddmmyylocal = (date) => {
  //
  //     let gridstartdate = date?.split("/");
  //
  // let grid_stdate = gridstartdate[1]+"/"+gridstartdate[0]+"/"+gridstartdate[2];

  //

  //     return grid_stdate
  //   };
  let schedulestartdate = document.querySelector("#STime")?.value?.split("/");
  return (
    <>
      {showinto && <Intro />}
      {getErrorMessage?.length > 0 && (
        <div className="error_message">
          <div className="error_Message_content">
            <div className="error_MSg_Con">
              <span> Message</span>{" "}
              <div className="close_but bg-light" onClick={error_close}>
                X
              </div>
            </div>
            <div className="error_content t-center">
              {[...new Set(getErrorMessage)]?.map((item, i) => (
                <p className="t-center" key={i}>
                  {item && ` ${item}`}
                </p>
              ))}
            </div>
          </div>
        </div>
      )}
      {backdatedbooking?.length > 0 && (
        <div className="error_message">
          <div className="error_Message_content">
            <div className="error_MSg_Con">
              <span> Error Message</span>{" "}
              {/* <div className="close_but bg-light" onClick={error_close}>
                X
              </div> */}
            </div>
            <div className="error_content t-center">
              {backdatedbooking?.map((item, i) => (
                <p className="t-center" key={i}>
                  {item && ` ${item}`}
                </p>
              ))}
            </div>
          </div>
        </div>
      )}
      {resererrormsg?.length > 0 && (
        <div className="error_message" style={{ width: "350px" }}>
          <div className="error_Message_content">
            <div className="error_MSg_Con">
              <span>{err_msg} message </span>{" "}
              {/* <div className="close_but bg-light" onClick={error_close}>
                X
              </div> */}
            </div>
            <div className="error_content" style={{ borderBottom: "0px !important" }}>
              {[...new Set(resererrormsg)]?.map((item, i) => (
                <p className="t-center" key={i} style={{ borderBottom: "0px !important" }}>
                  {" "}
                  {item && ` ${item}`}
                </p>
              ))}
            </div>
          </div>
        </div>
      )}
      {modificationerrormsg?.length > 0 && (
        <div className="error_message" style={{ width: "350px" }}>
          <div className="error_Message_content">
            <div className="error_MSg_Con">
              <span>Error message </span>{" "}
              {/* <div className="close_but bg-light" onClick={error_close}>
                X
              </div> */}
            </div>
            <div className="error_content" style={{ borderBottom: "0px !important" }}>
              {[...new Set(modificationerrormsg)]?.map((item, i) => (
                <p className="t-center" key={i} style={{ borderBottom: "0px !important" }}>
                  {" "}
                  {item && ` ${item}`}
                </p>
              ))}
            </div>
          </div>
        </div>
      )}

      {set_err_msg?.length > 0 && (
        <div className="error_message">
          <div className={`error_Message_content`}>
            <div className={`error_MSg_Con ${error_msg_class?.class}`}>
              <span>{error_msg_class?.msg} Message</span>{" "}
              {/* <div className="close_but bg-light" onClick={cls_err_msg}>
                X
              </div> */}
            </div>
            <div className={`error_content`}>
              {[...new Set(set_err_msg)]?.map((item, i) => (
                <p className="t-center" key={i}>
                  {item && ` ${item}`}
                </p>
              ))}
            </div>
          </div>
        </div>
      )}
      {set_modify_err_msg?.length > 0 && (
        <div className="error_message">
          <div className={`error_Message_content`}>
            <div className={`error_MSg_Con error_success`}>
              <span>Success Message</span>{" "}
            </div>
            <div className={`error_content`}>
              {[...new Set(set_modify_err_msg)]?.map((item, i) => (
                <p className="t-center" key={i}>
                  {item && ` ${item}`}
                </p>
              ))}
            </div>
          </div>
        </div>
      )}
      <Head title="Guest house booking" />
      {is_loading && <SpinnerTemplate />}
      {showerror_template && <Errow_page />}
      {GH_property_ClientName[0]?.length !== 0 ? shownewbooking && <NewBookingPopupTemplate /> : <Errortemplate />}
      {showblockdelete && <BlockDeleteData />}

      {addifields && (
        <AddAdditionalFields addifields={addifields} setAddifields={setAddifields} getaddcount={getaddcount} />
      )}
      {change_event_data && showChangedata ? (
        <ChangeEventTemplate showChangedata={showChangedata} showChangedatafunc={showChangedatafunc} />
      ) : null}


       {roomshiftingmodify && <ModifyShfiting modifyshiftdata={modifyshiftdata} editpopup={editpopup} showroomshiftingmodify={showroomshiftingmodify} showChangedata={showChangedata} showChangedatafunc={showChangedatafunc} /> } 
       {cancelpopup &&  <BookingCancelTemplate editpopup={editpopup} showEditpopup={showEditpopup} showcancelpopup={showcancelpopup}/>}
      {editpopup &&  <BookingModifyCancelOptionPopup 
      // editpopup={editpopup} showEditpopup={showEditpopup} 
      showcancelpopup={showcancelpopup}
      showEditpopup={showEditpopup}
      showroomshiftingmodify={showroomshiftingmodify}
      editpopup={editpopup}
      />}

      {showTr && (
        <div className="bg-low tr_list-select" style={{ display: showTr ? "block" : "none" }}>
          <TrRequest setEditordata={setEditordata} />
        </div>
      )}
      {showGh && (
        <div className="w-100  bg-low tr_list-select" style={{ display: showGh ? "block" : "none" }}>
          <HotelSearch />
        </div>
      )}
      <Content>
        <SelectHotelGrid />
        <div className="schedule-control-section">
          <div className="col-lg-12 control-section">
            <div className="control-wrapper">
              <>
                {scheduler_Data[0]?.groupData?.length > 0 ? (
                  <ScheduleComponent
                    cssClass="timeline-resource-grouping excel-export"
                    width="100%"
                    height="69vh"
                    // ref={scheduleObj}
                    ref={(schedule) => (scheduleObj = schedule)}
                    loadingIndicator={loadingIndicator}
                    selectedDate={
                      testdate !== null
                        ? testdate
                        : schedulestartdate
                        ? schedulestartdate[1] + "/" + schedulestartdate[0] + "/" + schedulestartdate[2]
                        : new Date()
                    }
                    workHours={{ highlight: true, start: "12:00", end: "11:59" }}
                    timeScale={{ enable: true, interval: 60 * 24, slotCount: set_occupency ? 1 : 2 }}
                    currentView="TimelineDay"
                    //enableLoadOnDemand={true}
                    workDays={workDays}
                    eventSettings={{
                      dataSource: set_occupency
                        ? occupency_Data[0]?.scheduleData
                        : readonly || scheduler_Data[0]?.scheduleData,
                      enableMaxHeight: true,
                      enableIndicator: true,
                      resourceField: ["Projects", "Categories"],
                      enableTooltip: true,
                      allowExcelExport: true,
                      tooltipTemplate: tooltipTemplate,
                      fields: {
                        isReadonly: "IsDone",
                      },
                    }}
                    group={{ resources: ["Projects", "Categories"] }}
                    rowAutoHeight={true}
                    enableAdaptiveUI={adaptiveUI}
                    showQuickInfo={false}
                    cellClick={onCellClick}
                    eventRendered={onEventRendered}
                    moreEventsClick={onMoreEventsClick}
                    dragStart={onItemDrag}
                    dragStop={onDragStop}
                    resizeStart={onItemDrag}
                    resizeStop={onResizeStop}
                    editorTemplate={editorTemplate}
                    popupOpen={onPopupOpen}
                    popupClose={onPopupClose}
                    actionBegin={onActionBegin}
                    actionComplete={onActionComplete}
                    renderCell={onRenderCell}
                    // groupRendered ={onRendergroup}
                    eventClick={onEventClick}
                  >
                    <ResourcesDirective>
                      <ResourceDirective
                        field="GHgroupid"
                        title="Choose Project"
                        name="Projects"
                        allowMultiple={false}
                        dataSource={set_occupency ? occupency_Data[0]?.groupData : scheduler_Data[0]?.groupData}
                        textField="text"
                        idField="id"
                        colorField="color"
                      ></ResourceDirective>
                      <ResourceDirective
                        field="GHRoomid"
                        title="Category"
                        name="Categories"
                        allowMultiple={true}
                        dataSource={set_occupency ? occupency_Data[0]?.roomData : scheduler_Data[0]?.roomData}
                        textField="text"
                        idField="id"
                        groupIDField="groupId"
                        colorField="color"
                      ></ResourceDirective>
                    </ResourcesDirective>
                    <ViewsDirective>
                      <ViewDirective
                        option="TimelineDay"
                        interval={dayscount || iniDate}
                        //virtual scroll and  room name select
                      //  allowVirtualScrolling={scheduler_Data[0]?.roomData?.length > 35 && true}
                      />
                    </ViewsDirective>
                    <Inject services={[TimelineViews, Resize, DragAndDrop]} />
                  </ScheduleComponent>
                ) : (
                  <div className="mt-5" style={{ textAlign: "center", fontWeight: "bold", fontSize: "1.2rem" }}>
                    Select Guest House Name and click submit to display data
                  </div>
                )}
              </>
            </div>
          </div>
        </div>
      </Content>
    </>
  );
}
export default React.memo(TimelineGrouping);
